import { Box, Typography } from '@material-ui/core';

import React from 'react';
import { useStyle } from '../style';
import { IInitialValueType } from '../type';
import Moment from 'moment';

interface IProps {
  formValues: IInitialValueType;
}

const CouponPreview: React.FC<IProps> = ({ formValues }) => {
  const classes = useStyle();

  return (
    <>
      <Box className={classes.couponPreview} display="flex" flexDirection={'column'} alignItems="flex-start">
        <Box display={'flex'}>
          <Typography variant="h5">
            <strong>{formValues.code}</strong>
          </Typography>
        </Box>
        <Box display={'flex'} marginBottom={1}>
          <Typography variant="h6">{formValues.name}</Typography>
        </Box>
        <Box display={'flex'} marginBottom={3}>
          <Typography variant="body1">{formValues.description}</Typography>
        </Box>
        <Box display={'flex'} marginBottom={1}>
          {formValues.orderType.map((type: string, index: number) => {
            return (
              <Typography key={index} variant="subtitle2" className={classes.borderedText}>
                {type}
              </Typography>
            );
          })}
        </Box>
        <Box display={'flex'} marginBottom={1}>
          {formValues.endDate && formValues.endDate !== '' && (
            <Typography variant="subtitle2" className={classes.borderedText}>
              Valid till: {Moment(formValues.endDate).format('MMM/DD/YYYY')}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CouponPreview;
