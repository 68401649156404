import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Box, Button, CircularProgress, MenuItem, Select, Typography } from '@material-ui/core';
import { useStyle } from '../style';
import { useHistory } from 'react-router-dom';
import { useActiveRestaurant } from 'hooks/restaurant';
import { useGetCoupons } from 'graphql/hooks/coupons.hooks';
import { ICouponType } from '../type';
import { convertPriceTo } from 'util/number';
const CouponsList = () => {
  const classes = useStyle();
  const history = useHistory();

  const { restaurantId } = useActiveRestaurant();

  const { data: coupons, loading } = useGetCoupons(restaurantId);

  const openCoupon = (couponId: string) => {
    history.push(`/home/${restaurantId}/coupon/${couponId}`);
  };
  if (loading) {
    return (
      <Box display={'flex'} justifyContent="center" alignItems={'center'} width="100%">
        <CircularProgress size={40} />
      </Box>
    );
  }
  return (
    <Box>
      {/* <Box display={'flex'} justifyContent="flex-end" width={'100%'} paddingY={2}>
        <Select id="demo-simple-select-error-1" className={classes.selectDroprDown} value={couponsToShow} onChange={(e) => onChangeCouponType(e.target.value)}>
          <MenuItem value={'ACTIVE'} key={'ACTIVE'}>
            Active
          </MenuItem>
          <MenuItem value={'HISTORY'} key={'HISTORY'}>
            History
          </MenuItem>
        </Select>
      </Box> */}
      <Paper className={classes.root}>
        <TableContainer>
          <Table stickyHeader aria-label="coupon data table">
            <TableHead>
              <TableRow>
                <TableCell>Coupon Code</TableCell>
                <TableCell>Description</TableCell>
                <TableCell># of Promotion Used</TableCell>
                <TableCell>$ Revenue Generated</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {coupons && coupons.length > 0 ? (
                coupons.map((couponItem: ICouponType, index: number) => {
                  return (
                    <TableRow key={index} style={{ cursor: 'pointer' }} onClick={() => openCoupon(couponItem.id)}>
                      <TableCell>
                        <strong>{couponItem.code}</strong>
                      </TableCell>
                      <TableCell>{couponItem.description}</TableCell>
                      <TableCell>{couponItem.used}</TableCell>
                      <TableCell> {convertPriceTo(couponItem.totalSales, 'DOLLAR') === '' ? 0 : convertPriceTo(couponItem.totalSales, 'DOLLAR')}</TableCell>
                      <TableCell>{couponItem.startDate}</TableCell>
                      <TableCell>{couponItem.endDate}</TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No Coupons added yet
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default CouponsList;
