import { gql } from 'apollo-boost';
import { Coupon } from 'graphql/fragments/coupon.fragments';

export default gql`
  query ownerGetCoupons($input: OwnerGetCouponsInput) {
    ownerGetCoupons(input: $input) {
      ...Coupon
    }
  }
  ${Coupon}
`;
