import React from 'react';
import AppRoutes from 'routes';
import { Box } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { getTheme } from '@lokobee/lokobee-ui';
import { LoaderStore } from 'store';
import { SnackbarProvider } from 'notistack';
import { AuthProvider } from 'fbase/authContext';
import { FirebaseContext, Firebase } from 'fbase';
import Loader from 'components/Loader';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import useClearCache from 'hooks/useClearCache';

const App: React.FC = () => {
  const { state, refetchCacheAndReload } = useClearCache();

  if (!state.loading && !state.isLatestVersion) {
    // You can decide how and when you want to force reload
    refetchCacheAndReload();
  }
  // let isDialogClosed = JSON.parse(localStorage.getItem('isDialogClosed') || 'null') || false;
  // const [openDialog, setOpenDialog] = useState<boolean>(!isDialogClosed);

  if (state.loading) {
    return null;
  }

  return (
    <ThemeProvider theme={getTheme()}>
      <FirebaseContext.Provider value={{ firebase: new Firebase() }}>
        <AuthProvider>
          <LoaderStore>
            <SnackbarProvider
              preventDuplicate={true}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Box display="flex" flexDirection="column" height="100%" bgcolor="white">
                  <Box flex={1} overflow="scroll">
                    <Loader />
                    <AppRoutes />
                  </Box>
                </Box>
              </MuiPickersUtilsProvider>
            </SnackbarProvider>
          </LoaderStore>
        </AuthProvider>
      </FirebaseContext.Provider>
    </ThemeProvider>
  );
};

export default App;
