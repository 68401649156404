import { Box } from '@material-ui/core';
import { useActiveRestaurant } from 'hooks/restaurant';
import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { IInitialValueType } from '../type';
import AddEditCouponForm from './AddEditCouponForm';
import { useGetCouponByCouponId } from 'graphql/hooks/coupons.hooks';
import { convertPriceTo } from 'util/number';
import { useSnackbar } from 'notistack';

const demoEmptyCoupon = {
  code: '',
  description: '',
  startDate: null,
  endDate: null,
  minSpending: '',
  discountValue: '',
  maxDiscount: '',
  dayOfWeek: [0, 1, 2, 3, 4, 5, 6],
  orderType: ['TAKEOUT', 'DELIVERY', 'DINING'],
  name: '',
  isPrivate: false,
  discountType: 'PERCENT',
  isSingleUse: false
};
const AddEditCoupon = () => {
  const history = useHistory();
  const snackbar = useSnackbar();

  const { restaurantId } = useActiveRestaurant();

  const { couponid } = useParams() as any;
  const { data, loading } = useGetCouponByCouponId(couponid);

  const [initialValues, setInitialValues] = useState<IInitialValueType>(demoEmptyCoupon);
  const [isViewCoupon, setIsViewCoupon] = useState<boolean>(false);

  useEffect(() => {
    if (data && data.getCouponByCouponId) {
      const { code, description, startDate, endDate, minSpending, discountValue, maxDiscount, dayOfWeek, orderType, name, isPrivate, discountType, isSingleUse, used } = data.getCouponByCouponId;
      const submittedOrderTypes: string[] = [...orderType];

      setInitialValues({
        code: code,
        description: description,
        startDate: startDate,
        endDate: endDate,
        minSpending: convertPriceTo(minSpending, 'DOLLAR'),
        discountValue: discountType === 'PERCENT' ? discountValue : convertPriceTo(discountValue, 'DOLLAR'),
        maxDiscount: convertPriceTo(maxDiscount, 'DOLLAR'),
        dayOfWeek: dayOfWeek,
        orderType: submittedOrderTypes,
        name: name,
        isPrivate: isPrivate,
        discountType: discountType,
        isSingleUse: isSingleUse,
        used: used
      });
      setIsViewCoupon(true);
    }
    if (data && data.getCouponByCouponId === null) {
      snackbar.enqueueSnackbar('Oops!, Some went wrong.', {
        variant: 'error'
      });
      // history.push(`/home/${restaurantId}/new-coupon`);
    }
  }, [couponid, data, history, restaurantId, snackbar]);

  if (loading) {
    return null;
  }
  return (
    <Box display={'flex'} flexDirection="column" flex={1}>
      <Box marginBottom={2}>
        <AddEditCouponForm initialValues={initialValues} isViewCoupon={isViewCoupon} />
      </Box>
    </Box>
  );
};

export default AddEditCoupon;
