import { Box, Button, Divider, Typography } from '@material-ui/core';
import React from 'react';
import CouponsList from './CouponsList';
import AddIcon from '@material-ui/icons/Add';
import { useStyle } from './style';
import { useHistory } from 'react-router-dom';
import { useActiveRestaurant } from 'hooks/restaurant';
import { useGetRestaurantById } from 'graphql/hooks/restaurant.hooks';

const Promotion = () => {
  const classes = useStyle();

  const history = useHistory();
  const { restaurantId } = useActiveRestaurant();
  const { data } = useGetRestaurantById(restaurantId || '', 'STATUS');

  if (!data) {
    return null;
  }
  return (
    <>
      <Box bgcolor="white" padding={1}>
        <Box display={'flex'} justifyContent="space-between" width="100%">
          <Typography variant="h5">Promotions</Typography>
          <Box>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => {
                history.push(`/home/${restaurantId}/new-coupon`);
              }}>
              Create Promotions
            </Button>
          </Box>
        </Box>

        <Divider className={classes.divider} />
        <CouponsList />
      </Box>
    </>
  );
};

export default Promotion;
