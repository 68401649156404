import { Box, Button, CircularProgress, Drawer, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useStyles } from './style';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useFindReportOrder, useGetOrdersReports } from 'graphql/hooks/orders.hooks';
import { useActiveRestaurant } from 'hooks/restaurant';
import RefreshIcon from '@material-ui/icons/Refresh';
import { convertPrice } from 'util/number';
import ClearIcon from '@material-ui/icons/Clear';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import OrderDetails from './OrderDetails';
import { calculateServiceCharges } from './utils';

const orderNumberFormat = '0000-00000';

const OrderReport = () => {
  const classes = useStyles();

  const { restaurantId } = useActiveRestaurant();

  const [init, setInit] = useState(true);

  const [orders, setOrders] = useState<any[]>([]);

  const [orderType, setOrderType] = useState('ALL');

  const [searchText, setSearchText] = useState('');

  const [startDate, setStartDate] = useState(
    moment()
      .subtract('weeks', 1)
      .hour(0)
      .minute(0)
      .second(0)
  );
  const [endDate, setEndDate] = useState(moment());

  const [selectedOrder, setSelectedOrder] = useState<any>(null);

  const onOrderTypeChange = (type: string) => {
    setOrderType(type);
  };

  const handleDateChange = (date: MaterialUiPickersDate, name: string) => {
    if (date) {
      if (name === 'startDate') {
        setStartDate(
          Moment(date)
            .hour(0)
            .minute(0)
            .second(0)
        );
      }
      if (name === 'endDate') {
        setEndDate(
          Moment(date)
            .hour(0)
            .minute(0)
            .second(0)
        );
      }
    }
  };

  const { data, fetchOrders, fetchMoreData, fetching, hasMore } = useGetOrdersReports(restaurantId);

  let { findOrder, data: searchData, loading } = useFindReportOrder();

  useEffect(() => {
    if (init && restaurantId) {
      fetchOrders({
        variables: {
          input: {
            bizId: restaurantId,
            startDate: startDate.valueOf(),
            endDate: endDate.valueOf(),
            orderType: orderType === 'ALL' ? undefined : orderType
          }
        }
      });
      setInit(false);
    }
  }, [endDate, fetchOrders, init, orderType, restaurantId, startDate]);

  useEffect(() => {
    if (searchText === '') {
      if (data && data.getBizOrderReport) {
        const { getBizOrderReport } = data;
        setOrders(getBizOrderReport.orders);
      }
    }
  }, [data, searchText]);

  useEffect(() => {
    if (searchData && searchData.getReportOrderByOrderNumber && !loading) {
      setOrders([searchData.getReportOrderByOrderNumber]);
    } else {
      setOrders([]);
    }
  }, [loading, searchData]);

  const fetchData = () => {
    if (searchText !== '') {
      const len = orderNumberFormat.length;

      findOrder({
        variables: {
          input: {
            bizId: restaurantId,
            orderNumber: orderNumberFormat.slice(0, len - searchText.length) + searchText
          }
        }
      });
    } else {
      fetchOrders({
        variables: {
          input: {
            bizId: restaurantId,
            startDate: startDate.valueOf(),
            endDate: endDate.valueOf(),
            orderType: orderType === 'ALL' ? undefined : orderType
          }
        }
      });
    }
  };

  const types: any = {
    TAKEOUT: 'Takeout',
    DELIVERY: 'Delivery',
    DINING: 'Dining'
  };

  return (
    <Box paddingY={2}>
      <Box>
        <Box display={'flex'} alignItems={'center'}>
          <Box flex={1}>
            <Typography variant="h5">Restaurant Orders</Typography>
          </Box>
          <Box flex={2} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
            <Box paddingX={0.5}>
              <KeyboardDatePicker
                size="small"
                autoOk
                margin="normal"
                inputVariant="outlined"
                variant="inline"
                format="MM/dd/yyyy"
                label="Start Date"
                name="startDate"
                value={startDate}
                onChange={(date) => handleDateChange(date, 'startDate')}
                className={classes.picker}
              />
            </Box>
            <Box paddingX={0.5}>
              <KeyboardDatePicker
                size="small"
                autoOk
                margin="normal"
                inputVariant="outlined"
                variant="inline"
                format="MM/dd/yyyy"
                label="End Date"
                name="endDate"
                value={endDate}
                onChange={(date) => handleDateChange(date, 'endDate')}
                className={classes.picker}
              />
            </Box>
            <Box paddingX={0.5}>
              <FormControl size="small" variant="outlined" fullWidth={true}>
                <InputLabel>Type</InputLabel>
                <Select variant="outlined" label="type" value={orderType} onChange={(e) => onOrderTypeChange(e.target.value as string)}>
                  <MenuItem value={'ALL'} key={'ALL'}>
                    ALL
                  </MenuItem>
                  <MenuItem value={'TAKEOUT'} key={'TAKEOUT'}>
                    TAKEOUT
                  </MenuItem>

                  <MenuItem value={'DINING'} key={'DINING'}>
                    DINING
                  </MenuItem>

                  <MenuItem value={'DELIVERY'} key={'DELIVERY'}>
                    DELIVERY
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box paddingX={0.5}>
              <TextField
                value={searchText}
                onChange={(e) => setSearchText(e.currentTarget.value)}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={() => {
                        setSearchText('');
                        searchData = null;
                      }}>
                      <ClearIcon fontSize="small" />
                    </InputAdornment>
                  )
                }}
                placeholder="Order ID"
                variant="outlined"
              />
            </Box>
            <Box>
              <RefreshIcon onClick={fetchData} className={classes.icon} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box paddingY={4}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Order ID</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Customer</TableCell>
              <TableCell>PAYMENT</TableCell>
              <TableCell>Subtotal</TableCell>
              <TableCell>You Earn</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((orderData, index) => {
              const { order, serviceCharges } = orderData;
              const subTotal = order.subTotal && order.subTotal.intValue && order.subTotal.shift ? convertPrice(order.subTotal.intValue, order.subTotal.shift) : '';
              const customer = order.buyer && order.buyer.displayName ? order.buyer.displayName : '';

              const orderTime = order.createdAt ? moment(order.createdAt) : null;

              const status = order.status;

              const calculatedCharges = calculateServiceCharges(order, serviceCharges);

              return (
                <TableRow key={order.id}>
                  <TableCell>
                    <Box>
                      <Typography variant="subtitle1" color={status === 'CANCELLED' || status === 'REJECTED' ? 'error' : 'inherit'}>
                        {order.orderNumber}
                      </Typography>
                      {(status === 'CANCELLED' || status === 'REJECTED') && <Typography variant="subtitle2" color="error">{`ORDER ${status}`}</Typography>}
                    </Box>
                  </TableCell>
                  <TableCell>{types[order.type]}</TableCell>
                  <TableCell>{orderTime?.format('MM/DD/yyyy HH:mm A')}</TableCell>
                  <TableCell>{customer}</TableCell>
                  <TableCell>{order.paymentType === 'ONSITE' ? 'OFFLINE' : 'STRIPE'}</TableCell>
                  <TableCell>$ {subTotal}</TableCell>
                  <TableCell>{status !== 'CANCELLED' && status !== 'REJECTED' ? `$ ${calculatedCharges.earnings}` : ``} </TableCell>
                  <TableCell>
                    <NavigateNextOutlinedIcon
                      onClick={() => {
                        setSelectedOrder(orderData);
                      }}
                      className={classes.toggleIcon}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>

      {selectedOrder && restaurantId && (
        <Drawer
          anchor={'right'}
          open={true}
          onClose={() => {
            setSelectedOrder(null);
          }}>
          <OrderDetails orderData={selectedOrder} />
        </Drawer>
      )}

      {hasMore && searchText === '' && (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Button variant="contained" color="primary" disableElevation={true} size="small" onClick={fetchMoreData} disabled={fetching}>
            FETCH MORE
          </Button>
          {fetching && (
            <Box paddingX={1}>
              <CircularProgress size={20} />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default OrderReport;
