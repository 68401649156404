import gql from 'graphql-tag';

export const Profile = gql`
  fragment Profile on Restaurant {
    name {
      text
    }
    subName {
      text
    }
    type
    customDomainUrl
    logo {
      id
      url
    }
    phone
    address {
      text
      street
      city
      state
      country
      zipcode
      lat
      lng
    }
    addressNote {
      text
    }
    description {
      text
    }
    taxRate {
      shift
      intValue
    }
    i18n {
      timezone
    }
    email
    reportEmails
    orderEmails
    enableDishNote
    nanoid
    hasStripeAccount
  }
`;

export const BusinessHours = gql`
  fragment BusinessHours on Restaurant {
    bizHours {
      serviceType
      serviceName
      hours {
        openDay
        closeDay
        openHour
        openMinute
        closeHour
        closeMinute
      }
    }
  }
`;

export const Table = gql`
  fragment Table on Restaurant {
    tables {
      tableName
      seats
      cardImg {
        id
        url
      }
    }
  }
`;

export const Announcement = gql`
  fragment Announcement on Restaurant {
    announcement {
      text
    }
  }
`;

export const RewardSetting = gql`
  fragment RewardSetting on Restaurant {
    issueRewardPoint
    rewardSetting {
      minStartPoints
      maxRedeemPointsPerUsage
      minRedeemPurchase
      pricePointRatio
    }
  }
`;

export const Gallery = gql`
  fragment Gallery on Restaurant {
    images {
      md {
        id
        url
      }
    }
  }
`;

export const PaymentSettings = gql`
  fragment PaymentSettings on Restaurant {
    enableTakeoutOrder
    enableDiningOrder
    enableDeliveryOrder
    takeoutWaitMinutes
    opsSetting {
      disableTakeoutOrderDetailDisplay
      disableDeliveryOrderDetailDisplay
      disableDiningOrderDetailDisplay
    }
    takeoutPaymentSetting {
      onlinePaymentTypes
      onsitePaymentTypes
      serviceFee {
        amount {
          intValue
          shift
        }
        threshold {
          intValue
          shift
        }
      }
      onlinePaymentFee {
        amount {
          intValue
          shift
        }
        threshold {
          intValue
          shift
        }
      }
      tipDefaultPercent
      tipPercentOptions
    }
    diningPaymentSetting {
      onlinePaymentTypes
      onsitePaymentTypes
      serviceFee {
        amount {
          intValue
          shift
        }
        threshold {
          intValue
          shift
        }
      }
      onlinePaymentFee {
        amount {
          intValue
          shift
        }
        threshold {
          intValue
          shift
        }
      }
      tipDefaultPercent
      tipPercentOptions
    }
    deliveryPaymentSetting {
      onlinePaymentTypes
      onsitePaymentTypes
      serviceFee {
        amount {
          intValue
          shift
        }
        threshold {
          intValue
          shift
        }
      }
      onlinePaymentFee {
        amount {
          intValue
          shift
        }
        threshold {
          intValue
          shift
        }
      }

      tipDefaultPercent
      tipPercentOptions
    }
    hasStripeAccount
    stripeInProgress
    deliverySetting {
      waitMinutes
      freeDeliveryAmount {
        intValue
        shift
      }
      minDeliveryAmount {
        intValue
        shift
      }
      fees {
        mile
        fee {
          intValue
          shift
        }
      }
    }
  }
`;

export const Status = gql`
  fragment Status on Restaurant {
    onlineStatus
    isLokobeePartner
  }
`;
