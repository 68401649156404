import { useState, useEffect } from 'react';
import { useActiveRestaurant } from 'hooks/restaurant';
import { useQueryWithLoader, useMutationWithLoader } from 'hooks/loader';
import { createCouponMutation, deleteCouponMutation } from 'graphql/mutations/coupon.mutation';
import { getCouponsByRestaurantQuery } from 'graphql/query/coupon.query';
import { filter } from 'lodash';
import ownerGetCouponsQuery from 'graphql/query/Coupons/ownerGetCoupons.query';
import getCouponByCouponIdQuery from 'graphql/query/Coupons/getCouponByCouponId.query';
import { ICouponType } from 'pages/Home/RestaurantDashboard/Promotions/type';

export const useDeleteCoupon = (bizId: string, code: string) => {
  const [deleteCoupon, { data, error, loading }] = useMutationWithLoader(deleteCouponMutation, {
    variables: {
      input: {
        bizId,
        code
      }
    },
    update: (cache) => {
      const variables = {
        input: {
          bizId
        }
      };

      const { getCouponsByRestaurant: oldData }: any = cache.readQuery({
        query: getCouponsByRestaurantQuery,
        variables
      });

      cache.writeQuery({
        variables,
        query: getCouponsByRestaurantQuery,
        data: { getCouponsByRestaurant: filter(oldData, (coupon) => coupon.code !== code) }
      });
    }
  });

  return {
    deleteCoupon,
    data,
    error,
    loading
  };
};

////////////////////////////////////////////////////////////////////////////////////
//                              Regular hooks                                     //
////////////////////////////////////////////////////////////////////////////////////
export const useGetCoupons = (bizId: string | null) => {
  const [coupons, setCoupons] = useState<ICouponType[]>([]);

  const { data, error, loading } = useQueryWithLoader(ownerGetCouponsQuery, {
    fetchPolicy: 'network-only',
    variables: {
      skip: !bizId,
      input: {
        bizId: bizId
      }
    }
  });

  useEffect(() => {
    if (data) {
      setCoupons(data.ownerGetCoupons);
    }
  }, [data]);

  return {
    loading,
    data: coupons,
    error
  };
};

export const useGetCouponByCouponId = (couponId: string | null) => {
  const { loading, data, error } = useQueryWithLoader(getCouponByCouponIdQuery, {
    skip: !couponId,
    variables: {
      input: {
        couponId
      }
    }
  });

  return {
    loading,
    data,
    error
  };
};

export const useCreateCoupon = () => {
  const { restaurantId } = useActiveRestaurant();

  const [createCoupon, { data, loading, error }] = useMutationWithLoader(createCouponMutation, {
    update: (cache, { data: { createCoupon: newData } }) => {
      const variables = {
        input: {
          bizId: restaurantId
        }
      };

      const { ownerGetCouponsQuery: oldData }: any = cache.readQuery({
        query: ownerGetCouponsQuery,
        variables
      });

      cache.writeQuery({
        variables,
        query: ownerGetCouponsQuery,
        data: { ownerGetCouponsQuery: oldData ? [newData, ...oldData] : [newData] }
      });
    }
  });

  return {
    createCoupon,
    data,
    loading,
    error
  };
};
