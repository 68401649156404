import React, { useImperativeHandle } from 'react';
import { IRestaurantProfile } from './types';
import { useFormik, FormikProps } from 'formik';
import validate from './validations';
import { useStyles } from './style';
import * as _ from 'lodash';
import { IAddress } from '../types';
import { Grid, Typography, FormControl, Select, MenuItem, FormHelperText, InputLabel, Box } from '@material-ui/core';
import UploadImage from 'components/UploadImage';
import InfoInputField from './InfoInputField';
import PhoneInput from 'react-phone-number-input';
import AddressInputField from 'components/AddressInputField';
import CloseIcon from '@material-ui/icons/Close';
import 'react-phone-number-input/style.css';
import { useUpdateRestaurantMutation } from 'graphql/hooks/restaurant.hooks';
import timeZones from './timeZone';

interface ITimeZone {
  name: string;
  link: string;
}

interface IProps {
  initialValues: IRestaurantProfile;
  nextStep: () => void;
  getUploadId: (filename: string, imageData: string) => Promise<string | null>;
}

const RestaurantProfile = React.forwardRef(({ initialValues, nextStep, getUploadId }: IProps, ref) => {
  const classes = useStyles();

  const { updateRestaurant } = useUpdateRestaurantMutation('PROFILE');

  const formik: FormikProps<IRestaurantProfile> = useFormik({
    initialValues,
    validate,
    enableReinitialize: true,
    onSubmit: (formValues) => handleFormSubmit(formValues)
  });

  const handleFormSubmit = async (formValues: IRestaurantProfile) => {
    try {
      let uploadId;

      const { logoImageData } = formValues;
      if (logoImageData) {
        uploadId = await getUploadId(logoImageData.filename, logoImageData.imageFiledata);

        if (!uploadId || uploadId === '') {
          throw Error('Image Upload failed');
        }
      } else {
        uploadId = formValues.logo ? formValues.logo.id : undefined;
      }

      const response = await updateRestaurant({
        variables: {
          input: {
            name: { lang: 'en', text: formValues.name },
            taxRate: formValues.taxRate,
            phone: formValues.phone,
            address: { ...formValues.address },
            logoId: uploadId,
            description: { lang: 'en', text: formValues.description },
            i18n: {
              langs: ['en'],
              timezone: formValues.timeZone,
              defaultLang: 'en'
            },
            type: formValues.restaurantType,
            subName: formValues.subName !== '' ? { lang: 'en', text: formValues.subName } : null,
            addressNote: formValues.addressNote !== '' ? { lang: 'en', text: formValues.addressNote } : null,
            email: formValues.email,
            reportEmails: formValues.reportEmails.replace(/\s/g, '').split(','),
            orderEmails: formValues.orderEmails.replace(/\s/g, '').split(',')
          }
        }
      });

      const { data } = response;

      if (data && data.updateRestaurant) {
        nextStep();
      }
    } catch (e) {
      console.log(e);
    }
  };

  useImperativeHandle(ref, () => {
    return {
      submitForm: () => {
        return formik.submitForm();
      }
    };
  });

  /* 
    custom change handler for tax field to add % at end and make sure the values are between 0 to 100 
  */
  const onChange = (e: any, name: string) => {
    formik.setValues({
      ...formik.values,
      [name]: e
    });
  };

  const onAddressSelect = (selectedAddress: IAddress) => {
    const { text, country, state, city, zipcode, lat, lng, street } = selectedAddress;
    formik.setValues({
      ...formik.values,
      address: {
        text,
        country,
        state,
        city,
        zipcode,
        lat,
        lng,
        street: street
      }
    });
  };

  const onAddressChange = (addr: string) => {
    formik.setValues({
      ...formik.values,
      address: {
        text: '',
        country: '',
        state: '',
        city: '',
        zipcode: '',
        lat: 0,
        lng: 0,
        street: ''
      }
    });
  };

  const onCrop = (filename: string, imageFiledata: string) => {
    formik.setValues({
      ...formik.values,
      logoImageData: {
        filename,
        imageFiledata
      }
    });
  };

  const { logo } = formik.values;

  return (
    <React.Fragment>
      <Grid container={true} className={classes.formContainer}>
        <Grid item={true} md={4}>
          <UploadImage width={300} height={200} onCrop={onCrop} initialValue={logo ? logo.url : undefined} />
          {formik.errors.logo && (
            <Typography variant="body2" color="error">
              Logo Image Required
            </Typography>
          )}
        </Grid>
        <Grid item={true} xs={12} md={4}>
          <InfoInputField
            name="name"
            type="text"
            placeholder="Name *"
            inputLabel="Name"
            formik={formik}
            inputProps={{
              maxLength: 200
            }}
            required={true}
            variant="outlined"
          />
          <InfoInputField
            name="subName"
            type="text"
            placeholder="Sub Name"
            inputLabel="Sub Name"
            formik={formik}
            inputProps={{
              maxLength: 200
            }}
            required={true}
            variant="outlined"
          />
          <InfoInputField
            name="taxRate"
            type="text"
            placeholder="Tax *"
            inputLabel="Tax"
            formik={formik}
            required={true}
            variant="outlined"
            InputProps={{
              endAdornment: <h3>%</h3>
            }}
          />

          <InfoInputField inputLabel="Phone" type="hidden" required={true}>
            <PhoneInput
              placeholder="Phone Number *"
              className={formik.errors.phone && formik.touched.phone ? `${classes.customInputError} + ${classes.customInput}` : `${classes.customInput}`}
              name="phone"
              defaultCountry="US"
              value={formik.values.phone}
              onChange={(value: string) => onChange(value, 'phone')}
              onBlur={formik.handleBlur('phone')}
              variant="outlined"
              label="Phone Number"
            />
            {formik.errors.phone && formik.touched.phone && (
              <Typography variant="caption" color="error">
                {formik.errors.phone}
              </Typography>
            )}
          </InfoInputField>

          <InfoInputField inputLabel="Address" type="hidden" required={true}>
            <AddressInputField
              name="address"
              variant="outlined"
              validationError={formik.touched.address && formik.errors.address !== '' && formik.errors.address !== undefined}
              value={formik.values.address.text}
              defaultValue={formik.initialValues.address.text}
              onChange={onAddressChange}
              onBlur={formik.handleBlur}
              onAddressSelect={onAddressSelect}
              errorMsg={formik.errors.address}
              label="Address"
            />
          </InfoInputField>
          <InfoInputField name="addressNote" type="text" placeholder="Address Note" inputLabel="Address Note" formik={formik} required={true} variant="outlined" />
          <InfoInputField>
            <FormControl error={formik.errors.timeZone !== '' && formik.errors.timeZone !== undefined && formik.touched.timeZone} fullWidth={true}>
              <InputLabel>Timezone</InputLabel>
              <Select
                id="demo-simple-select-error"
                value={formik.values.timeZone}
                className={
                  formik.errors.timeZone !== '' && formik.errors.timeZone !== undefined && formik.touched.timeZone ? `${classes.customInputError} + ${classes.customInput}` : `${classes.customInput}`
                }
                onChange={(e) => onChange(e.target.value, 'timeZone')}
                onBlur={formik.handleBlur('timeZone')}>
                {_.map(timeZones, (zone: ITimeZone) => {
                  return (
                    <MenuItem value={zone.name} key={zone.name}>
                      {zone.name}
                    </MenuItem>
                  );
                })}
              </Select>
              {formik.errors.timeZone !== '' && formik.errors.timeZone !== undefined && formik.touched.timeZone && <FormHelperText>{formik.errors.timeZone}</FormHelperText>}
            </FormControl>
          </InfoInputField>
        </Grid>
        <Grid item={true} xs={12} md={4}>
          <InfoInputField>
            <FormControl variant="outlined" fullWidth={true}>
              <InputLabel id="demo-simple-select-outlined-label">Restaurant type</InputLabel>

              <Select
                fullWidth={true}
                name="restaurantType"
                id="demo-simple-select-outlined"
                labelId="demo-simple-select-outlined-label"
                label="Restaurant type"
                value={formik.values.restaurantType}
                onChange={(e: any) => onChange(e.target.value, 'restaurantType')}>
                <MenuItem value="REGULAR_RESTAURANT">Regular Restaurant</MenuItem>
                <MenuItem value="FOOD_TRUCK">Food Truck</MenuItem>
              </Select>
            </FormControl>
          </InfoInputField>
          <InfoInputField
            id="standard-multiline-flexible"
            name="description"
            type="text"
            multiline
            rowsMax="6"
            placeholder="Description"
            inputLabel="Description"
            formik={formik}
            inputProps={{
              maxLength: 300
            }}
            required={true}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <React.Fragment>
                  <CloseIcon
                    cursor="pointer"
                    onClick={() => {
                      formik.setValues({ ...formik.values, description: '' });
                    }}
                  />
                </React.Fragment>
              )
            }}
          />
          <InfoInputField name="email" type="email" placeholder="Contact Email *" inputLabel="Contact Email" formik={formik} required={true} variant="outlined" />
          <InfoInputField
            name="orderEmails"
            type="text"
            placeholder="Order emails (comma seperated) *"
            inputLabel="Order Emails ( comma seperated )"
            formik={formik}
            required={true}
            variant="outlined"
          />

          <InfoInputField
            name="reportEmails"
            type="text"
            placeholder="Report emails (comma seperated) *"
            inputLabel="Report Emails ( comma seperated )"
            formik={formik}
            required={true}
            variant="outlined"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
});

export default RestaurantProfile;
