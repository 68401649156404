import { isValidPhoneNumber } from 'react-phone-number-input';
import { IRestaurantProfile } from './types';
import * as Yup from 'yup';

const validate = (values: IRestaurantProfile) => {
  const errors: any = {};

  const taxRegex = new RegExp(/^100$|^100.0$|^100.00$|^\d{0,2}(\.\d{1,2})?$/);
  const webSiteRegex = new RegExp(/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/);
  const validateEmail = Yup.string()
    .email('Invalid email')
    .min(2, 'Too short!')
    .max(100, 'Too long!');

  if (!values.name || values.name.trim() === '') errors.name = 'Required';

  if (!values.description || values.description.trim() === '') errors.description = 'Required';

  if (!values.taxRate || values.taxRate.trim() === '') errors.taxRate = 'Required';
  if (values.taxRate.trim() !== '' && !taxRegex.test(values.taxRate)) errors.taxRate = 'Invalid tax value';

  if (!values.phone || values.phone.trim() === '') errors.phone = 'Required';
  else if (!isValidPhoneNumber(values.phone)) errors.phone = 'Invalid phone number';

  // if (!values.logo && !values.logoImageData) errors.logo = 'Logo Image Required';

  if (!values.timeZone || values.timeZone.trim() === '') errors.timeZone = 'Required';

  if (!values.address.text || values.address.text.trim() === '') errors.address = 'Invalid Address';

  if (!values.address.city || values.address.city.trim() === '') errors.address = 'Invalid Address';

  if (!values.address.state || values.address.state.trim() === '') errors.address = 'Invalid Address';

  if (!values.address.country || values.address.country.trim() === '') errors.address = 'Invalid Address';

  if (!values.address.street || values.address.street.trim() === '') errors.address = 'Invalid Address';

  if (!values.address.zipcode || values.address.zipcode.trim() === '') errors.address = 'Invalid Address';

  const emailCheck = validateEmail.isValidSync(values.email);

  if (!emailCheck) errors.email = 'Invalid Email';
  if (!values.email || values.email.trim() === '') errors.email = 'Required';

  const orderEmails = values.orderEmails
    .toString()
    .replace(/,\s*/g, ',')
    .split(',');

  orderEmails.forEach((email: string) => {
    if (!validateEmail.isValidSync(email)) errors.orderEmails = 'Invalid Email';
  });

  const reportEmails = values.reportEmails
    .toString()
    .replace(/,\s*/g, ',')
    .split(',');

  reportEmails.forEach((email: string) => {
    if (!validateEmail.isValidSync(email)) errors.reportEmails = 'Invalid Email';
  });

  if (values.orderEmails.trim() === '') errors.orderEmails = 'Required';
  if (values.reportEmails.trim() === '') errors.reportEmails = 'Required';

  return errors;
};

export default validate;
