import React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { parseISO } from 'date-fns';
interface IProps {
  name: string;
  value: string | null;
  label: string;
  errors: string | null;
  minDate?: any;
  maxDate?: any;
  readOnly: boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
  onChange: (date: MaterialUiPickersDate, name: string) => void;
  onBlur: (e: any) => void;
  fullWidth?: boolean;
  styleClass?: any;
}

const DatePicker: React.FC<IProps> = ({ name, value, label, errors, minDate = '', maxDate = '', readOnly, disablePast = true, disableFuture = false, onChange, onBlur, fullWidth, styleClass }) => {
  return (
    <KeyboardDatePicker
      readOnly={readOnly}
      InputProps={{
        readOnly: readOnly
      }}
      fullWidth={fullWidth}
      disableToolbar
      disablePast={!minDate && disablePast}
      disableFuture={disableFuture}
      autoOk
      margin="normal"
      inputVariant="outlined"
      variant="inline"
      format="MM/dd/yyyy"
      minDate={minDate}
      maxDate={maxDate}
      name={name}
      value={value != null ? parseISO(value) : value}
      onChange={(date) => onChange(date, name)}
      onBlur={onBlur}
      label={label}
      error={!!errors}
      helperText={errors}
      className={styleClass}
    />
  );
};

export default DatePicker;
