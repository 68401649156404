import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, ListItemText, MenuItem, Select, TextField, Tooltip, Typography } from '@material-ui/core';
import DatePicker from 'components/DatePicker';
import { FormikProps, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useStyle } from '../style';
import { IInitialValueType } from '../type';
import { validationSchema } from './validation';
import moment from 'moment-timezone';
import Moment from 'moment';
import Big from 'big.js';
import SaveIcon from '@material-ui/icons/Save';
import _, { chain, filter, isUndefined } from 'lodash';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { AttachMoney } from '@material-ui/icons';
import { useActiveRestaurant } from 'hooks/restaurant';
import { useHistory } from 'react-router-dom';
import CouponPreview from './CouponPreview';
import Switch from '@material-ui/core/Switch';
import { useCreateCoupon, useGetCoupons } from 'graphql/hooks/coupons.hooks';
import { convertPriceTo } from 'util/number';
import { useSnackbar } from 'notistack';
import { CurrencyInputField } from '@lokobee/lokobee-ui';
import InfoIcon from '@material-ui/icons/Info';

interface IProps {
  isViewCoupon: boolean;
  initialValues: IInitialValueType;
}
const AddEditCouponForm: React.FC<IProps> = ({ initialValues, isViewCoupon }) => {
  const classes = useStyle();
  const history = useHistory();
  const snackbar = useSnackbar();

  const { restaurantId } = useActiveRestaurant();
  const { data: coupons } = useGetCoupons(restaurantId || '');

  const { createCoupon } = useCreateCoupon();

  const handleFormSubmit = async (formValues: IInitialValueType) => {
    const submittedOrderTypes: string[] = [...formValues.orderType];

    try {
      formik.setSubmitting(true);
      const isDuplicateCode = coupons.some((item) => item.code === formValues.code);
      if (!isDuplicateCode) {
        const response = await createCoupon({
          variables: {
            input: {
              bizId: restaurantId,
              code: formValues.code,
              name: formValues.name,
              description: formValues.description,
              discountType: formValues.discountType,
              discountValue: formValues.discountType === 'DOLLAR' ? parseFloat(convertPriceTo(formValues.discountValue, 'CENT')) : formValues.discountValue,
              maxDiscount: parseFloat(convertPriceTo(formValues.maxDiscount, 'CENT')),
              startDate: formValues.startDate,
              endDate: formValues.endDate,
              minSpending: parseFloat(convertPriceTo(formValues.minSpending, 'CENT')) || 0,
              isPrivate: formValues.isPrivate,
              isSingleUse: formValues.isSingleUse,
              orderType: submittedOrderTypes,
              dayOfWeek: formValues.dayOfWeek
            }
          }
        });

        if (response) {
          if (response.data) {
            const feedbackText = response.data && response.data.createCoupon ? 'Coupon saved successfully' : 'Coupon updated successfully';

            snackbar.enqueueSnackbar(feedbackText, {
              variant: 'success'
            });
            history.push(`/home/${restaurantId}/promotions`);
            formik.setSubmitting(false);
          }

          if (response.errors?.length) {
            const errorText = response.errors[0].message;

            snackbar.enqueueSnackbar(errorText, {
              variant: 'error'
            });
            formik.setSubmitting(false);
          }
        } else {
          snackbar.enqueueSnackbar('Failed to save coupon', {
            variant: 'error'
          });
          formik.setSubmitting(false);
        }
      } else {
        snackbar.enqueueSnackbar('Promotion code already exists', {
          variant: 'error'
        });
        formik.setSubmitting(false);
      }
    } catch (err) {
      snackbar.enqueueSnackbar('Failed to save coupon', {
        variant: 'error'
      });
      formik.setSubmitting(false);
    }
  };
  const formik: FormikProps<IInitialValueType> = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (formValues) => handleFormSubmit(formValues)
  });
  const daysofWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  const orderTypes = ['TAKEOUT', 'DELIVERY', 'DINING'];

  const { values, handleChange, handleSubmit, handleBlur, touched, errors, setFieldValue } = formik;
  const [maxDiscount, setMaxDiscount] = useState<string | undefined>('');

  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === 'code') {
      const re = /^[a-zA-Z0-9]*$/;

      if (value.includes(' ') || !re.test(value)) {
        return false;
      }
      event.target.value = value.toUpperCase();
    }
    if (name === 'discountValue') {
      const re = /^(?!0+$)[0-9]*$/;

      if (!re.test(value) && values.discountType === 'PERCENT') {
        return false;
      }

      if (values.discountType === 'PERCENT' && value && Big(value).gte(100)) {
        return false;
      }
    }
    if (name === 'maxUse' || name === 'maxDiscount') {
      let intVal = parseInt(value, 10);
      if (intVal && Big(intVal).lt(1)) {
        return false;
      }
    }
    if (name === 'maxUse') {
      const re = /^[0-9]*$/;
      if (!re.test(value)) {
        return false;
      }
    }
    if (name === 'couponCost') {
      // if (value && Big(parseInt(value, 10)).lt(1)) {
      //   return false;
      // }
      const re = /^[0-9]*$/;
      if (!re.test(value)) {
        return false;
      }
    }

    handleChange(event);
  };

  /**
   * Setting description
   */
  useEffect(() => {
    const { minSpending, discountType, discountValue, maxDiscount } = values;

    const discount = !!discountValue ? Big(discountValue).toString() : '';

    const value = !!discountValue && Big(discountValue).gt(0) ? 'Get ' + (discountType === 'PERCENT' ? `${discount}%` : `$${discount}`) + ' Off' : '';

    const minSpendThreshold = !!minSpending && !!discountValue && Big(minSpending).gt(0) ? ` on Purchase over $${minSpending}` : '';

    const maxDiscountThreshold = !!maxDiscount && !!discountValue && Big(maxDiscount).gt(0) ? `, Max Discount $${maxDiscount}` : '';

    const desc = !!value ? `${value}${minSpendThreshold}${maxDiscountThreshold}` : '';

    setFieldValue('description', desc);
  }, [values.name, values.minSpending, values.discountType, values.discountValue, values.maxDiscount, values, setFieldValue]);

  const handleDateChange = (date: MaterialUiPickersDate, name: string) => {
    if (date) {
      let y = date?.getFullYear();
      let m = date?.getMonth();
      let d = date?.getDate();
      const selectedDateCustom = Moment()
        .year(y)
        .month(m)
        .date(d)
        .format('YYYY-MM-DD');
      setFieldValue(name, selectedDateCustom);
      if (name === 'startDate') {
        setFieldValue(
          'endDate',
          Moment(selectedDateCustom)
            .add(1, 'month')
            .format('yyyy-MM-DD')
        );
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box bgcolor="white" padding={1}>
        <Box display={'flex'} justifyContent="space-between" alignItems={'flex-start'} width="100%">
          <Box>
            <Typography variant="h5">{isViewCoupon ? 'Promotion' : 'Add a new Promotion'}</Typography>
          </Box>

          <Box display={'flex'}>
            {!isViewCoupon && (
              <Box marginRight={1}>
                <Button disabled={formik.isSubmitting || !formik.isValid} variant="contained" color="primary" startIcon={<SaveIcon />} type="submit">
                  Save
                </Button>
              </Box>
            )}

            <Button
              variant="outlined"
              onClick={() => {
                history.push(`/home/${restaurantId}/promotions`);
              }}>
              {isViewCoupon ? 'Back' : 'Cancel'}
            </Button>
          </Box>
        </Box>

        <Divider className={classes.divider} />
        <Box marginTop={4}>
          <Grid container spacing={4}>
            <Grid item xs={9}>
              <Grid container className={classes.rowSpacing} spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    fullWidth={true}
                    name="code"
                    type="text"
                    variant="outlined"
                    label="Promotion Code"
                    value={values.code}
                    placeholder="Upto 15 characters"
                    onChange={onChange}
                    inputProps={{
                      readOnly: isViewCoupon,
                      maxLength: 15,
                      minLength: 2
                    }}
                    onBlur={handleBlur}
                    error={!!touched.code && !!errors.code}
                    helperText={!!touched.code && errors.code}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth={true}
                    name="name"
                    type="text"
                    variant="outlined"
                    label="Title (Optional)"
                    placeholder="eg: Birthday, Aniversary, Christmas Sale.."
                    value={values.name}
                    onBlur={handleBlur}
                    onChange={onChange}
                    inputProps={{ readOnly: isViewCoupon, maxLength: 100 }}
                    error={!!touched.name && !!errors.name}
                    helperText={!!touched.name && errors.name}
                  />
                </Grid>
              </Grid>
              <Grid container className={classes.rowSpacing} spacing={2}>
                <Grid item xs={4}>
                  <DatePicker
                    styleClass={classes.resetMargin}
                    fullWidth={true}
                    label="Start Date"
                    readOnly={isViewCoupon}
                    name="startDate"
                    disablePast={false}
                    value={values.startDate}
                    minDate={moment().format('MM/DD/yyyy')}
                    maxDate={values.endDate ? Moment(values.endDate) : Moment().add(1, 'year')}
                    onBlur={handleBlur}
                    onChange={handleDateChange}
                    errors={!!touched.startDate && !!errors.startDate ? errors.startDate : ''}
                  />
                </Grid>
                <Grid item xs={4}>
                  <DatePicker
                    styleClass={classes.resetMargin}
                    fullWidth={true}
                    label="End Date"
                    readOnly={isViewCoupon}
                    name="endDate"
                    value={values.endDate}
                    minDate={values.startDate ? Moment(values.startDate) : Moment()}
                    maxDate={Moment(values.startDate).add(1, 'year')}
                    onBlur={handleBlur}
                    onChange={handleDateChange}
                    errors={!!touched.endDate && !!errors.endDate ? errors.endDate : ''}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CurrencyInputField
                    fullWidth={true}
                    name="minSpending"
                    variant="outlined"
                    label="Min Spending"
                    value={values.minSpending}
                    onBlur={handleBlur}
                    inputProps={{ readOnly: isViewCoupon }}
                    onChange={onChange}
                    error={!!touched.minSpending && !!errors.minSpending}
                    helperText={!!touched.minSpending && errors.minSpending}
                  />
                </Grid>
              </Grid>
              <Grid container className={classes.rowSpacing} spacing={2}>
                <Grid item xs={4}>
                  {values.discountType === 'DOLLAR' ? (
                    <CurrencyInputField
                      fullWidth={true}
                      name="discountValue"
                      variant="outlined"
                      label={`Discount (${values.discountType === 'DOLLAR' ? '$' : '%'})`}
                      onBlur={handleBlur}
                      onChange={onChange}
                      className={classes.textFieldPadding}
                      value={values.discountValue}
                      InputProps={{
                        readOnly: isViewCoupon,
                        startAdornment: values.discountType === 'DOLLAR' ? <AttachMoney /> : '',
                        endAdornment: (
                          <FormControl className={classes.formControl}>
                            <Select
                              readOnly={isViewCoupon}
                              native
                              value={values.discountType}
                              name="discountType"
                              onChange={(e: any) => {
                                if (!isViewCoupon) {
                                  handleChange(e);
                                  setFieldValue('discountValue', '');
                                  if (e.target.value === 'DOLLAR') {
                                    setMaxDiscount(values.maxDiscount);
                                    setFieldValue('maxDiscount', '');
                                  } else {
                                    setFieldValue('maxDiscount', maxDiscount);
                                  }
                                }
                              }}>
                              <option value="PERCENT">%</option>
                              <option value="DOLLAR">$</option>
                            </Select>
                          </FormControl>
                        )
                      }}
                      error={!!touched.discountValue && !!errors.discountValue}
                      helperText={!!touched.discountValue && errors.discountValue}
                    />
                  ) : (
                    <TextField
                      fullWidth={true}
                      name="discountValue"
                      type="number"
                      variant="outlined"
                      label={`Discount (${values.discountType === 'DOLLAR' ? '$' : '%'})`}
                      onBlur={handleBlur}
                      onChange={onChange}
                      value={values.discountValue}
                      InputProps={{
                        readOnly: isViewCoupon,
                        startAdornment: values.discountType === 'DOLLAR' ? <AttachMoney /> : '',
                        endAdornment: (
                          <FormControl className={classes.formControl}>
                            <Select
                              readOnly={isViewCoupon}
                              native
                              value={values.discountType}
                              name="discountType"
                              onChange={(e: any) => {
                                if (!isViewCoupon) {
                                  handleChange(e);
                                  setFieldValue('discountValue', '');
                                  setFieldValue('maxDiscount', '');
                                  if (e.target.value === 'DOLLAR') {
                                    setMaxDiscount(values.maxDiscount);
                                  } else {
                                    setFieldValue('maxDiscount', maxDiscount);
                                  }
                                }
                              }}>
                              <option value="PERCENT">%</option>
                              <option value="DOLLAR">$</option>
                            </Select>
                          </FormControl>
                        )
                      }}
                      error={!!touched.discountValue && !!errors.discountValue}
                      helperText={!!touched.discountValue && errors.discountValue}
                    />
                  )}
                </Grid>
                <Grid item xs={4}>
                  <CurrencyInputField
                    fullWidth={true}
                    name="maxDiscount"
                    variant="outlined"
                    label="Max Discount"
                    value={values.maxDiscount}
                    onBlur={handleBlur}
                    InputProps={{
                      readOnly: isViewCoupon,
                      startAdornment: <AttachMoney />
                    }}
                    disabled={values.discountType === 'DOLLAR'}
                    onChange={onChange}
                    error={!!touched.maxDiscount && !!errors.maxDiscount}
                    helperText={!!touched.maxDiscount && errors.maxDiscount}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl variant="outlined" style={{ width: '100%' }} error={!!touched.dayOfWeek && !!errors.dayOfWeek}>
                    <InputLabel>Days of Week</InputLabel>
                    <Select
                      readOnly={isViewCoupon}
                      autoWidth
                      multiple
                      value={values.dayOfWeek}
                      renderValue={(selected: any) => {
                        if (selected.length >= 7) {
                          return 'Whole Week';
                        } else {
                          const days = chain(selected)
                            .orderBy(
                              [
                                (day: string) =>
                                  Moment()
                                    .day(day)
                                    .format('d')
                              ],
                              ['asc']
                            )
                            .map((day: string) =>
                              Moment()
                                .day(day)
                                .format('ddd')
                            )
                            .value();

                          return days.join(', ');
                        }
                      }}
                      onChange={(e: any) => {
                        setFieldValue(
                          'dayOfWeek',
                          filter(e.target.value, (a) => !isUndefined(a))
                        );
                      }}
                      label="Days of Week"
                      name="dayOfWeek"
                      inputProps={{
                        onBlur: handleBlur
                      }}>
                      <Box marginLeft={3.5}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.dayOfWeek.length >= 7}
                              onChange={(e: any) => {
                                if (e.target.checked) {
                                  setFieldValue('dayOfWeek', [0, 1, 2, 3, 4, 5, 6]);
                                } else {
                                  setFieldValue('dayOfWeek', []);
                                }
                              }}
                            />
                          }
                          label="Whole Week"
                        />
                      </Box>
                      {daysofWeek.map((day: string, index: number) => (
                        <MenuItem key={day} value={index}>
                          <Checkbox checked={values.dayOfWeek.includes(index)} />
                          <ListItemText primary={day} />
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{!!touched.dayOfWeek && errors.dayOfWeek}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container className={classes.rowSpacing} spacing={2}>
                <Grid item xs={4}>
                  <FormControl variant="outlined" style={{ width: '100%' }} error={!!touched.orderType && !!errors.orderType}>
                    <InputLabel>Order Type</InputLabel>
                    <Select
                      autoWidth
                      readOnly={isViewCoupon}
                      multiple
                      value={values.orderType}
                      renderValue={(selected: any) => {
                        return selected.join(', ');
                      }}
                      onChange={handleChange}
                      label="Order Type"
                      name="orderType"
                      inputProps={{
                        onBlur: handleBlur
                      }}>
                      {orderTypes.map((value) => (
                        <MenuItem key={value} value={value}>
                          <Checkbox checked={values.orderType.includes(value)} />
                          <ListItemText primary={value} />
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{!!touched.orderType && errors.orderType}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={4}></Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Box marginBottom={2}>
                <CouponPreview formValues={values} />
              </Box>
              <Box display={'flex'} width="100%" justifyContent={'space-between'}>
                <Box display={'flex'} alignItems="center">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.isSingleUse}
                        onChange={(_e: any, checked: boolean) => {
                          if (!isViewCoupon) {
                            formik.setValues({
                              ...values,
                              isSingleUse: checked
                            });
                          }
                        }}
                        name="isSingleUse"
                        inputProps={{ readOnly: isViewCoupon, 'aria-label': 'secondary checkbox' }}
                      />
                    }
                    label="One Time Use"
                  />
                  <Tooltip title={<Typography variant="subtitle1">Limit to One Time Use per Account for this Promotion</Typography>} placement="bottom" aria-label="info" arrow>
                    <InfoIcon className={classes.infoBtn} />
                  </Tooltip>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </form>
  );
};

export default AddEditCouponForm;
