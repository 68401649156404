import React from 'react';
import { Switch, Route } from 'react-router-dom';
import RestaurantHome from './RestaurantHome';
import DishAddOrEditPage from './DishAddOrEditPage';
import Announcement from './Announcement';
import Reward from './Reward';
import EmployeeManagement from './EmployeeManagement';
import RestaurantInfo from './RestaurantInfo';
import PaymentSetup from './PaymentSetup';
import PizzaConfiguration from './PizzaConfiguration';
import Promotions from './Promotions';
import RestaurantFeedBack from './RestaurantFeedBack';
import Analytics from './Analytics';
import WebAutomation from 'pages/WebsiteAutomation/WebAutomationForm';
import BlogsList from 'pages/WebsiteAutomation/Blogs/BlogsList';
import Msp from './MSP';
import TextMarketing from './TextMarketing';
import AddEditCoupon from './Promotions/AddEditCoupon';
import OrderReport from './OrderReport';
import AddEditSingleBlogArticle from 'pages/WebsiteAutomation/Blogs/AddEditSingleBlogArticle';

const DashboardRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path="/home/:restaurantId/blogs/edit/:blogId" component={AddEditSingleBlogArticle} />
      <Route path="/home/:restaurantId/blogs/add-new-article" component={AddEditSingleBlogArticle} />
      <Route path="/home/:restaurantId/info" component={RestaurantInfo} />
      <Route path="/home/:restaurantId/analytics" component={Analytics} />
      <Route path="/home/:restaurantId/promotions" component={Promotions} />
      <Route path="/home/:restaurantId/announcement" component={Announcement} />
      <Route path="/home/:restaurantId/reward" component={Reward} />
      <Route path="/home/:restaurantId/websiteform" component={WebAutomation} />
      <Route path="/home/:restaurantId/new-dish" component={DishAddOrEditPage} />
      <Route path="/home/:restaurantId/dish/:dishId" component={DishAddOrEditPage} />
      <Route path="/home/:restaurantId/employeemanagement" component={EmployeeManagement} />
      <Route path="/home/:restaurantId/paymentsetup" component={PaymentSetup} />
      <Route path="/home/:restaurantId/pizzaconfig" component={PizzaConfiguration} />
      <Route path="/home/:restaurantId/ratings" component={RestaurantFeedBack} />
      <Route path="/home/:restaurantId/msp" component={Msp} />
      <Route path="/home/:restaurantId/text-marketing" component={TextMarketing} />
      <Route path="/home/:restaurantId/coupon/:couponid" component={AddEditCoupon} />
      <Route path="/home/:restaurantId/new-coupon" component={AddEditCoupon} />
      <Route path="/home/:restaurantId/orderreport" component={OrderReport} />
      <Route path="/home/:restaurantId/blogs" component={BlogsList} />
      <Route path="/home/:restaurantId" component={RestaurantHome} />
    </Switch>
  );
};

export default DashboardRoutes;
